
$(document).ready(function() {

    $('.header').elementDirectionAndOffsetByScroll('init', {
        offsetSmall : (150 - 80), // Best practice: header-height - header-small-height
        offsetOut : 80, // 150, // Best practice: header-height
        classInit : 'header--fixed',
        callbackScrollDirection : function (scrollDirection) {
            
            // console.log(scrollDirection)
            // console.log(this)
            
            //$(".navigation").removeClass("navigation--open");
			$(".header__button--menu").removeClass("header__button--active");
			
			if ( $(".search").hasClass("search--staystrong") ) {
				
			} else {
				$(".search").removeClass("search--open");	
				$(".header__button--search").removeClass("header__button--active");
			}
        }

        /*
        container : $(document),
        classInit : 'element-direction-and-offset-by-scroll',
        classScrollDirectionDown : 'scroll-direction-is-down',
        classScrollDirectionUp : 'scroll-direction-is-up',
        classOffsetSmall: 'scroll-offset-small-is-reached',
        classOffsetOut : 'scroll-offset-out-is-reached',
        callbackScrollDirection : function (scrollDirection) {
            console.log(scrollDirection)
            console.log(this)
        },
        callbackOffsetSmall : function (scrollOffsetSmall) {
            console.log(scrollOffsetSmall)
            console.log(this)
        }*/
    });

    //$('.fixed-header').elementDirectionAndOffsetByScroll('destroy');
});


