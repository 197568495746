
$(document).ready(function(){

	// var $carousel = $('.carousel__wrap');


	var $carousel_SlidesToShow = 3;
	var $carousel_SlidesToShow_Tablet = 2;
	var $carousel_SlidesToShow_Mobile = 1;

	var $carousel_variableWidth = false;
	var $carousel_adaptiveHeight = false;
	var $carousel_centerMode = false;


	// Getting current slide and total number of slides for a compact replacement of the dot nav on mobile

	





	$('.carousel').each(function(){
			
		
		// Setting the max numbers of slides shown
		$carousel = $(this);
		
		var $carousel_status = $('.carousel__status',this);
		var $carousel_caption = $('.carousel__caption',this);
		var $caption;
		
		$('.carousel__wrap',this).on('onLoad init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			
			
			//console.log( currentSlide );
			
			//console.log( $(this).find('.slick-current').attr('data-slick-index') );
			$caption = $(this).find('.slick-current').attr('data-caption');
			
			/*
			var $caption = currentSlide.attr('data-caption');
	        // var $description = $(this).attr('data-description');

			console.log($caption);
			// console.log($description);

			if ($description.length > 0) {
				$carousel_caption.html('<p>' + $description + '</p>');
			} else 
			
			*/
			if ($caption.length > 0) {
				$carousel_caption.html( $caption );

			} else {
				$carousel_caption.html('');
			};
			
			
	        // currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
	
	        var i = (currentSlide ? currentSlide : 0) + 1;
	
	        $carousel_status.html('<span>' + i + '</span>' + '/' + '<span>' + slick.slideCount + '</span>');
	        
	        
	        
	        
	        
	        
	    });


		if ( $(this).hasClass("carousel--col-1") ) {
			$carousel_SlidesToShow = 1;
			$carousel_SlidesToShow_Tablet = 1;
			$carousel_SlidesToShow_Mobile = 1;
		}


		if ( $(this).hasClass("carousel--col-2") ) {
			$carousel_SlidesToShow = 2;
			$carousel_SlidesToShow_Tablet = 2;
			$carousel_SlidesToShow_Mobile = 1;
		}


		if ( $(this).hasClass("carousel--col-4") ) {
			$carousel_SlidesToShow = 4;
			$carousel_SlidesToShow_Tablet = 3;
			$carousel_SlidesToShow_Mobile = 2;
		}


		if ( $(this).hasClass("carousel--col-5") ) {
			$carousel_SlidesToShow = 5;
			$carousel_SlidesToShow_Tablet = 3;
			$carousel_SlidesToShow_Mobile = 2;
		}


		// Setting the handling of differently sized images

		if ( $(this).hasClass("carousel--variable-width") ) {
			$carousel_variableWidth = true;
		}


		if ( $(this).hasClass("carousel--center-mode") ) {
			$carousel_centerMode = true;
		}


		if ( $(this).hasClass("carousel--adaptive-height") ) {
			$carousel_adaptiveHeight = true;
			$carousel_SlidesToShow = 1;
			$carousel_SlidesToShow_Tablet = 1;
			$carousel_SlidesToShow_Mobile = 1;
		}

		$(".carousel__wrap",this).slick({
			slidesToShow : $carousel_SlidesToShow,
			slidesToScroll: $carousel_SlidesToShow,
			arrows : true,
			prevArrow: $carousel.find('.carousel__prev'),
			nextArrow: $carousel.find('.carousel__next'),
			dots: true,
			appendDots: $carousel.find(".carousel__dots"),
			slide : '.carousel__item',
			fade: false,
			speed: 500,
			autoplay : false,
			autoplaySpeed : 5000,
			variableWidth : $carousel_variableWidth,
			centerMode: $carousel_centerMode,
			adaptiveHeight: $carousel_adaptiveHeight,

			responsive : [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: $carousel_SlidesToShow_Tablet,
						slidesToScroll: $carousel_SlidesToShow_Tablet
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: $carousel_SlidesToShow_Mobile,
						slidesToScroll: $carousel_SlidesToShow_Mobile,
						dots: false,
						variableWidth : false,
						adaptiveHeight : false
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: false,
						variableWidth : false,
						adaptiveHeight : false
					}
				}
			]
		});

		$carousel_SlidesToShow = 3;
		$carousel_SlidesToShow_Tablet = 2;
		$carousel_SlidesToShow_Mobile = 1;
		$carousel_variableWidth = false;
		$carousel_adaptiveHeight = false;
		$carousel_centerMode = false;
	
	});
	
});