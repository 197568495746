$(document).ready(function(){


	$("a.lightbox").attr('rel','image-in-lightbox');
	$(".slideshow a.lightbox").attr('rel','slide-in-lightbox');
	$(".carousel a.lightbox").attr('rel','carousel-in-lightbox');


	// initialize fancybox
	$("a.lightbox").fancybox({
		margin: 0,
		padding: 80,
		autoSize: true,
		fitToView: true,
		fixed: false,
		openEffect: 'fade',
		closeEffect: 'fade',
		nextEffect: 'fade',
		prevEffect: 'fade',
		openSpeed: 250,
		closeSpeed: 250,
		nextSpeed: 250,
		prevSpeed: 250,
		arrows: true,
		closeBtn: true,
		loop: true,
		wrapCSS: 'gal-fancy',
		helpers:
		{
			title: {
				type: 'inside'
			},
			buttons: {
				position: 'bottom'
			}
		},
		beforeLoad: function(){

			var obj = $(this.element);
			var caption = obj.attr('data-caption');
			var credits = obj.attr('data-credits');
			var download = obj.attr('data-download');

			if ( obj.parent().hasClass("media") ) {
				if ( !caption ) caption = obj.parent().next().html();
				if ( !credits ) credits = obj.next().html();
			} else {
				if ( !caption ) caption = obj.parent().find(".caption").html();
				if ( !credits ) credits = obj.parent().find(".credits").html();
			}

			var text = '';
			text += '<div class="text">';
			if(credits) text += '<p class="credits">' + credits + '</p>';

			if(download) text += '<a href="' + download + '" title="Download starten" class="download" target="_blank">Download</a>';

			if(caption) text += '<p class="caption">' + caption + '</p>';

			text += '</div>';

			this.title = (caption || credits || download) ? text : "";

		},
		beforeShow: function(){
			// Disable right click
			$.fancybox.inner.bind("contextmenu", function(e){ return false; });
		}

	});
});