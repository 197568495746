//=require ../../../libraries/infinite-scroll-3.0.5.min.js


$(document).ready(function () {

    if ($('.teaser').next('.pagination').length > 0) {
        $('.teaser').infiniteScroll({
            path: '.pagination__next',
            append: '.teaser__item',
            history: false,
            button: '.pagination__load-more', // load pages on button click
            scrollThreshold: false,	// disable loading on scroll
            hideNav: '.pagination',
            status: '.pagination__load-status'
        });

    }

    //extra for ListAll, @Todo: remove debug
    function setupListAllInfiniteScroll() {
        var $container = $('.teaser');
        $container.off('append.infiniteScroll');
        $container.off('load.infiniteScroll');
        $container.off('request.infiniteScroll');
        //initialy the $container has no infiniteScroll

        try {
            $container.infiniteScroll('destroy');
        } catch (e) {
            console.log('catch in site/typo3conf/ext/lf_base/Resources/FrontendBlog/source/patterns/molecules/pagination/_pagination.js');
            console.log(e);
        }
        $container.infiniteScroll({
            debug: true,
            path: '.pagination__next',
            //path: path,
            append: '.teaser__item,.pagination__infinite-scroll,.pagination__next',
            history: false,
            button: '.pagination__load-more',
            scrollThreshold: false,
            hideNav: '.pagination',
            status: '.pagination__load-status'
        });
        $container.on('append.infiniteScroll', function (event, response, path, items) {
            var delay = 0;
            //console.log('Appended: ' + path, items);
            if (items) {
                $(items).each(function () {
                    var $item = $(this).hide().delay(delay).fadeIn('slow');
                    delay += 200;
                });
            }
            setupListAllInfiniteScroll();
        });

        $container.on( 'load.infiniteScroll', function( event, response, path ) {
            console.log( 'Loaded: ' + path );
            //setupListAllInfiniteScroll();
            //initialy remove controls from first rendering/previous request
            $('.pagination__next').remove();
            $('.pagination__infinite-scroll').remove();
        });

        $container.on('request.infiniteScroll', function (event, path) {
            console.log('Loading: ' + path);
        });
    }

    setupListAllInfiniteScroll();

});