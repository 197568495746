//=require ../../../libraries/jquery-validation/jquery.validate.js
//=require ../../../libraries/jquery-validation/localization/messages_de.js

$(document).ready(function () {

    // validation
    $('form').each(function () {

        // console.log( $(this).attr("id") + " _ " + $(this).attr("class") );
        var $thisForm = $(this);

        $thisForm.validate({
            invalidHandler: function (event, validator) {
                // remove waiting spinner form submit button
                $thisForm.removeClass("submitting");
				/*
				// 'this' refers to the form
				var errors = validator.numberOfInvalids();
				if (errors) {
					var message = errors == 1
					? 'You missed 1 field. It has been highlighted'
					: 'You missed ' + errors + ' fields. They have been highlighted';
					$("div.error span").html(message);
					$("div.error").show();
				} else {
					$("div.error").hide();
				}
				*/
            },
            errorPlacement: function (error, element) {
                if (element.attr("type") == "checkbox") {
                    if ($(element).parents('.checkbox__error-container')) {
                        error.appendTo($(element).parents('.checkbox__error-container'));
                    } else {
                        error.appendTo($(element).closest('.form__group'));
                    }
                } else if (element.attr("type") == "radio") {
                    if ($(element).parents('.radio__error-container')) {
                        error.appendTo($(element).parents('.radio__error-container'));
                    } else {
                        error.appendTo($(element).closest('.form__group'));
                    }
                } else {
                    // something else if it's not a checkbox or radio
                    error.appendTo($(element).closest('.form__group'));
                }
            }
        });

		// Require numbers for fields with data-field-type="number"
        $('[data-field-type="number"]', $thisForm).rules('add', {
			number: true
		});


	    $('[data-field-type="email"], [data-type="email"]').each(function () { //
	        $thisForm.rules('add', {
	            email: true
	        });
	    });


		$('[data-equal-to]').each(function() {
	        $thisForm.rules('add', {
	        	equalTo: '#' + $(this).attr('data-equal-to')
	        });
	    });

    });

    // Require .TLD in email adresses
	/*
	$.validator.addMethod(
		'email',
			function(value, element){
			return this.optional(element) || /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-\011\013\014\016-\177])*")@((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)$)|\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/i.test(value);
	});
	*/

    // TODO: Is this better than "validator.addMethod"?
    $.validator.methods.email = function (value, element) {
        return this.optional(element) || /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-\011\013\014\016-\177])*")@((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)$)|\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/i.test(value);
    }
});
