$(document).ready(function(){
	
	
	$('.twitterstream .row').imagesLoaded(function () {

			$(".twitterstream--masonary .row").isotope(
				{
					itemSelector: '.twitter__item',
					percentPosition: true,
					stamp: '.stamp',
					masonry: {
						// use outer width of grid-sizer for columnWidth
						// columnWidth: 33.333333%
		  			}
				}
			);
	});

});