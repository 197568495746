
window.addEventListener("load", function(){
	window.cookieconsent.initialise({
		"palette": null, // Styles in _cookieconsent.scss
		// "static": true, // use in conjunction with postion:banner reveal banner behind page
		// "position": "bottom-left", // Floating left
		// "position": "bottom-right", // Floating right
		// "position": "top", // Banner top
		// "position": "banner", // Banner top
		// leave out position entirely for banner bottom
		"content": {
			"message": "Wir verwenden Cookies, um einen guten Webservice zu ermöglichen. Wenn Sie durch unsere Seiten surfen, erklären Sie sich mit unseren Nutzungsbedingungen einverstanden.",
			"dismiss": "Schließen",
			"link": "Erfahren Sie mehr.",
			"href": "/datenschutz/"
		}
	})
});