// Remember:
// Every file in scripts/ acts as an entry-point and gets tranformed to build/. Feel free to create as much as you want.
// Files in subdirectories will be ignored but you can require them in entry-point-files.
// Add an _ to a file and it will just be ignored(good for tests).
// Add your entry-point-files to templates/layout/yourlayout.html





// ===============================================================================
// ATOMS
// ===============================================================================

// //=require ../../patterns/atoms/logo/logo.js
// //=require ../../patterns/atoms/forms/_selects.js
//=require ../../patterns/atoms/forms/_validation.js
// //=require ../../patterns/atoms/forms/password-equal.js
// //=require ../../patterns/atoms/forms/datepicker.js
// //=require ../../patterns/atoms/forms/rte.js
// //=require ../../patterns/atoms/forms/_file.js
// //=require ../../patterns/atoms/submitting/submitting.js
// //=require ../../patterns/atoms/tooltip/tooltip.js




// ===============================================================================
// MOLECULES
// ===============================================================================


// // =require ../../patterns/molecules/footer/footer.js
// // =require ../../patterns/molecules/main/main.js
//=require ../../patterns/molecules/textrow/textrow.js
// //=require ../../patterns/molecules/accordion/accordion.js
// // =require ../../patterns/molecules/navigation/navigation.js
// //=require ../../patterns/molecules/filter/filter.js
//=require ../../patterns/molecules/pagination/_pagination.js
// //=require ../../patterns/molecules/shares/shares.js
//=require ../../patterns/molecules/top/_top.js
//=require ../../patterns/molecules/carousel/_carousel.js
// //=require ../../patterns/molecules/hero/hero.js
//=require ../../patterns/molecules/lightbox/_lightbox.js
// //=require ../../patterns/molecules/teaser/teaser.js
//=require ../../patterns/molecules/twitterstream/_twitterstream.js
//=require ../../patterns/molecules/cookieconsent/_cookieconsent.js
//=require ../../patterns/molecules/search/_search.js
// //=require ../../patterns/molecules/steps/steps-reload.js
//=require ../../patterns/molecules/skip-link/_skip-link.js
// //=require ../../patterns/molecules/newsletter-sticky/_newsletter-sticky.js



// ===============================================================================
// ORGANISMS
// ===============================================================================

// //=require ../../patterns/organisms/facebookstream/facebookstream.js
// //=require ../../patterns/organisms/shop/shop.js
// //=require ../../patterns/organisms/shop/shop-detail/_shop-detail.js
// //=require ../../patterns/organisms/registration/_registration.js

//=require ../../patterns/organisms/header/_header.js


// ===============================================================================
// UTILITIES
// ===============================================================================



















