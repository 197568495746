
$(document).ready(function(){
	
	
	/*
	$(".header").attr("data-header-height", $(".header").height() );
	
	
	$(".header__button--menu").on("click",function(e){
		e.preventDefault();
		
		if ( $(this).hasClass("header__button--active") ) {
			
			// $(".navigation").removeClass("navigation--open");	
			$(this).removeClass("header__button--active");
			$(".header").removeClass("header__navigation--open");
			
			
			// if( $(".header").hasClass("scroll-offset-small-is-reached") == false ) {
				$(".main").css("padding-top", $(".header").attr("data-header-height") );
			// } 
			
			
		} else {
			
			// $(".navigation").addClass("navigation--open");	
			$(this).addClass("header__button--active");
			$(".header").addClass("header__navigation--open");
			
			
			
			// if( $(".header").hasClass("scroll-offset-small-is-reached") == false ) {
				$(".main").css("padding-top", $(".header").attr("data-header-height") + $(".navigation").height() );
			//} 
			
			
			$(".search").removeClass("search--open");	
			$(".header__button--search").removeClass("header__button--active");
			
		}
	});
	*/
	
	
		
	$(".header__button--menu").on("click",function(e){
		e.preventDefault();
		
		if ( $(this).hasClass("header__button--active") ) {
			
			$(".navigation").removeClass("navigation--open");	
			$(this).removeClass("header__button--active");
			
		} else {
			
			$(".navigation").addClass("navigation--open");	
			$(this).addClass("header__button--active");
			
			$(".search").removeClass("search--open");	
			$(".header__button--search").removeClass("header__button--active");
			
		}
	});
	
	
	$(".header__button--search").on("click",function(e){
		
		e.preventDefault();
		
		if ( $(this).hasClass("header__button--active") ) {
			
			$(".search").removeClass("search--open");	
			$(this).removeClass("header__button--active");
			
		} else {
			
			$(".search").addClass("search--open");	
			$(this).addClass("header__button--active");
			
			$(".navigation").removeClass("navigation--open");	
			$(".header__button--menu").removeClass("header__button--active");
			
		}
	});
	
	
	$(".navigation__close").on("click",function(e){
		e.preventDefault();
		
		$(".navigation").removeClass("navigation--open");	
		$(".header__button--menu").removeClass("header__button--active");
		
	});
	
	
	$(".search__close").on("click",function(e){
		e.preventDefault();
		
		$(".search").removeClass("search--open");	
		$(".header__button--search").removeClass("header__button--active");
		
	});
	
	
	
	
	if ( $("[data-search-display]").length  ) {
		
		
		$(".search").addClass("search--open");
		$(".search").addClass("search--staystrong");
		$(".header__button--search").addClass("header__button--active");
		
		$(".search__heading").text( $("[data-search-display]").attr("data-search-title") );
		
		if ( $("[data-search-results]").length ) {
			$(".search__heading").append( '<div class="search__results">' + $("[data-search-display]").attr("data-search-results") + '</div>' );
		}
	};
	
});